import dayjs from "dayjs";

let player;
function setupPlayer(videoId) {
  const tag = document.createElement("script");
  tag.src = "https://www.youtube.com/iframe_api";
  const firstScriptTag = document.getElementsByTagName("script")[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  window.onYouTubeIframeAPIReady = function () {
    player = new YT.Player("player", {
      host: "https://www.youtube-nocookie.com",
      width: "100%",
      videoId,
      playerVars: {
        playsinline: 1,
      },
      events: {
        onReady: (event) => {
          event.target.playVideo();
        },
      },
    });
  };
}

let start = 0;
let end = 0;
let state = "IDLE"; // "RECORDING", "LOOPING"

const loopingButton = document.getElementById("loopingButton");
const submitUrlInput = document.getElementById("submitUrlInput");
const loopInput = document.getElementById("loopInput");
const urlInputArea = document.getElementById("urlInputArea");
const urlInputAreaDescription = document.getElementById(
  "urlInputAreaDescription"
);
const loopingArea = document.getElementById("loopingArea");
const reloadLink = document.getElementById("reloadLink");
const currentLoopDescription = document.getElementById(
  "currentLoopDescription"
);
const currentLoopDescriptionFrom = document.getElementById(
  "currentLoopDescriptionFrom"
);
const currentLoopDescriptionTo = document.getElementById(
  "currentLoopDescriptionTo"
);

loopingButton.addEventListener("click", () => {
  if (state === "IDLE") {
    start = player.getCurrentTime();
    state = "RECORDING";
    loopingButton.textContent = "End looping";
    currentLoopDescription.style.display = "block";
    currentLoopDescriptionFrom.textContent = dayjs(0)
      .second(start)
      .format("mm:ss");
  } else if (state === "RECORDING") {
    end = player.getCurrentTime();
    currentLoopDescriptionTo.textContent = dayjs(0).second(end).format("mm:ss");
    state = "LOOPING";
    loopingButton.textContent = "Clear looping";
  } else if (state === "LOOPING") {
    state = "IDLE";
    loopingButton.textContent = "Start looping";
    currentLoopDescription.style.display = "none";
    currentLoopDescriptionFrom.textContent = "";
    currentLoopDescriptionTo.textContent = "..";
  }
});
function loop() {
  if (state === "LOOPING" && player.getCurrentTime() > end) {
    player.seekTo(start);
  }
  window.requestAnimationFrame(loop);
}
window.requestAnimationFrame(loop);

submitUrlInput.addEventListener("click", startVideo);
loopInput.addEventListener("keyup", (event) => {
  if (event.keyCode === 13) {
    event.preventDefault();
    startVideo();
  }
});
function startVideo() {
  const videoId = getYoutubeIdFromUrl(loopInput.value);
  if (videoId) {
    setupPlayer(videoId);
    loopingArea.style.display = "block";
    reloadLink.style.display = "inline";
    urlInputArea.style.display = "none";
    urlInputAreaDescription.style.display = "none";
  } else {
    // TODO error handling
  }
}
function getYoutubeIdFromUrl(url) {
  url = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  return undefined !== url[2] ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
}
